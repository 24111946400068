import imageIndex from "../images/imgaeIndex";

export const ServiceData = [
  {
    id: 1,
    slug: "interior-design-planning-services-thane",
    img: [
      imageIndex.DESIGNPLAN1,
      imageIndex.DESIGNPLAN2,
      imageIndex.DESIGNPLAN3,
    ],
    h1: ' Interior Design & Planning Services in Thane',
    title: "Design planning",
    alt:' Interior Design & Planning Services in Thane',
    descp: `<p>Interior Design & Planning Services in Thane</p>`,
    descp2: `<p>When it comes to creating a space that reflects your personality and meets your functional needs, choosing the right Interior Design & Planning Services in Thane is essential. At HNY Studio, we understand that your home or office is more than just a space; it’s a reflection of who you are and what you value. Our team of expert interior designers is dedicated to transforming your vision into reality, ensuring that every corner of your space resonates with your style and purpose.</p>`,
    descp3: `<h5>Comprehensive Interior Design Solutions</h5>`,
    descp4: `<p>Our Interior Design & Planning Services in Thane offer a comprehensive approach to designing both residential and commercial spaces. Whether you’re looking to renovate your home, design a new office, or plan a commercial space, we have the expertise to deliver exceptional results. Our services include everything from conceptual design and space planning to furniture selection, lighting design, and project management. We work closely with our clients to understand their needs and preferences, ensuring that every project is tailored to meet their specific requirements.</p>`,
    descp5: `<strong>Why Choose Our Services?</strong>`,
    descp6: `<p>Choosing our Interior Design & Planning Services in Thane means you’re investing in quality, creativity, and attention to detail. Our team is passionate about design and committed to delivering spaces that are not only aesthetically pleasing but also functional and sustainable. We believe that good design should enhance the quality of life, and we strive to create environments that inspire and uplift.</p>`,
    descp7: `<strong style="display: block; margin-top: 20px; margin-bottom: 10px;"> Here’s why our clients trust us:</strong>`,
    descp8: `<p><strong>Personalized Designs:</strong> <br /> We believe that every space should tell a unique story. Our designers work closely with you to understand your preferences and lifestyle, creating customized designs that reflect your personality.</p>`,
    descp9: `<strong>Experienced Team:</strong>`,
    descp10: `<p>With years of experience in the industry, our team has the expertise to handle projects of all sizes and complexities. From small apartments to large commercial spaces, we’ve done it all.</p>`,
    descp11: `<p><strong>Sustainable Practices</strong> <br /> We are committed to sustainability and use eco-friendly materials and practices wherever possible. Our designs are not only beautiful but also environmentally responsible.</p>`,
    descp12: `<p><strong>Attention to Detail</strong> <br /> We pay close attention to every detail, ensuring that every aspect of your space is perfect. From the choice of materials to the finishing touches, we ensure that everything meets our high standards.</p>`,
    descp13: `<h5>Transform Your Space Today</h5>`,
    descp14: `<p>If you’re looking to transform your space, look no further than HNY Studio’s Interior Design & Planning Services in Thane. Our expert team is here to guide you through every step of the process, from initial concept to final execution. Whether you’re dreaming of a cozy home, a chic office, or a stunning commercial space, we have the skills and experience to make it happen. Contact us today to schedule a consultation and take the first step towards creating the space of your dreams.</p>`,
  },
  {
    id: 2,
    slug: "modular-kitchen-designer-thane",
    img: [
      imageIndex.KITCHEN1,
      imageIndex.KITCHEN2,
      imageIndex.kitchen3,
    ],
    h1: 'Modular Kitchen Designer in Thane',
    alt:'Modular Kitchen Designer in Thane',
    title: "Kitchen Installation",
    descp1: `<p>When it comes to creating a living or working environment that truly reflects your personality and style, professional interior designing is the key. At HNY Studio, based in Waghbil-Thane West, Thane, we specialize in bringing your vision to life through our expert interior designing services. Whether you’re looking to revamp your home, renovate your office, or create a unique commercial space, our team of skilled designers is here to ensure your dreams become a reality.</p>`,
    descp2: `<strong>Why Choose HNY Studio for Interior Designing?</strong>`,
    descp3: `<p>At HNY Studio, we understand that each space is unique and requires a personalized approach. Our interior designing process begins with an in-depth consultation to understand your needs, preferences, and budget. From there, we create a customized design plan that aligns with your vision while incorporating the latest trends and technologies in the interior design industry.</p>`,
    descp4: `<p>Our commitment to excellence in interior designing means that we pay close attention to every detail, from selecting the perfect color palettes and materials to arranging furniture and accessories in a way that maximizes space and functionality. We pride ourselves on our ability to blend aesthetics with practicality, ensuring that your space is not only beautiful but also comfortable and functional.</p>`,
    descp5: `<strong>Our Comprehensive Interior Designing Services</strong>`,
    descp6: `<p>HNY Studio offers a wide range of interior designing services tailored to meet the specific needs of our clients in Thane and beyond. Our services include:</p>`,
    descp7: `<strong> Residential Interior Designing</strong> <br /> Transform your home into a sanctuary with our expert residential interior designing services. We handle everything from kitchen renovations and bathroom remodels to complete home makeovers. <span style="display: block; margin-top: 20px; margin-bottom: 10px;"/>`,
    descp8: `<strong> Commercial Interior Designing</strong> <br /> Create an inviting and efficient workspace with our commercial interior designing solutions. We work on offices, retail spaces, restaurants, and more, ensuring that your business environment is both stylish and conducive to productivity. <span style="display: block; margin-top: 20px; margin-bottom: 10px;"/>`,
    descp9: `<strong> Architectural Interior Designing</strong> <br /> Our architectural interior designing services focus on enhancing the structural aspects of your space, creating a cohesive and harmonious design that complements the architecture. <span style="display: block; margin-top: 20px; margin-bottom: 10px;"/>`,
    descp10: `<strong>Why Interior Designing Matters</strong>`,
    descp11: `<p>Investing in professional interior designing is not just about making your space look good; it's about improving your overall quality of life. A well-designed space can boost your mood, increase productivity, and even improve the value of your property. At HNY Studio, we are passionate about helping our clients achieve these benefits through our dedicated interior designing services.</p> <span style="display: block; margin-top: 20px; margin-bottom: 10px;"/>`,
    descp12: `<h5>Get Started with HNY Studio Today</h5>`,
    descp13: `<p>Ready to transform your space? Contact HNY Studio in Waghbil-Thane West, Thane, to schedule a consultation and learn more about how our interior designing services can help you create the environment of your dreams. Whether you’re looking for a simple update or a complete overhaul, our team is here to guide you every step of the way.</p> <span style="display: block; margin-top: 20px; margin-bottom: 10px;"/>`,
  },
  {
    id: 3,
    slug: "best-interior-decorator-thane",
    img: [
      imageIndex.FURNITURE1,
      imageIndex.FURNITURE2,
      imageIndex.FURNITURE3
    ],
    h1: 'Best Interior Decorator in Thane',
    alt:'Best Interior Decorator in Thane',
    title: "Furniture & Home Decor",
    descp1: `<p>Best Interior Decorator in Thane for Furniture & Home Decor</p>`,
    descp2: `<p>Welcome to HNY Studio, your ultimate destination for premium furniture and home decor solutions. As the best interior decorator in Thane, we take pride in transforming your living spaces into stunning works of art. With a blend of creativity and functionality, our expert team crafts interiors that reflect your personality while providing unparalleled comfort and style.</p>`,
    descp3: `<strong>Why Choose HNY Studio?</strong> `,
    descp4: `<p>At HNY Studio, we understand that your home is more than just a place to live—it's an extension of who you are. Our approach to interior design is rooted in the belief that every detail matters. From selecting the perfect furniture to choosing the right color palette, we ensure that every aspect of your home is meticulously planned and executed. Our reputation as the best interior decorator in Thane comes from our commitment to quality, innovation, and customer satisfaction.</p>`,
    descp5: `<strong>Furniture That Speaks Your Style</strong>`,
    descp6: `<p>Furniture is the heart of any home decor. Whether you're looking for contemporary designs or classic pieces, HNY Studio offers a wide range of furniture options that cater to every taste. Our collection includes luxurious sofas, elegant dining tables, chic coffee tables, and stylish wardrobes—all crafted with the finest materials to ensure durability and comfort. We believe that the right furniture can completely transform a space, and our team is dedicated to helping you find pieces that align with your vision.</p>`,
    descp7: `<strong>Home Decor That Defines Elegance</strong>`,
    descp8: `<p>Beyond furniture, our home decor offerings add the finishing touch to your interiors. From eye-catching wall art to bespoke lighting solutions, HNY Studio provides an array of decor items that enhance the aesthetic appeal of your space. As the best interior decorator in Thane, we source unique and high-quality decor pieces that make a statement. Our team of designers works closely with you to curate decor that complements your furniture and aligns with the overall theme of your home.</p>`,
    descp9: `<strong>Tailored Interior Design Services</strong>`,
    descp10: `<p>What sets us apart as the best interior decorator in Thane is our personalized approach to interior design. We take the time to understand your needs, preferences, and lifestyle, ensuring that every design element resonates with you. Our services include comprehensive design consultations, space planning, and project management, all aimed at delivering a seamless and stress-free experience. Whether you're renovating a single room or designing an entire home, HNY Studio is here to bring your vision to life.</p>`,
    descp11: `<strong>Contact Us Today</strong>`,
    descp12: `<p>Ready to create your dream kitchen? Contact us today to schedule a consultation. Let us be your partner in transforming your kitchen into a space that inspires creativity, comfort, and joy.</p>`,
    descp13: `<p>Whether you're renovating an existing kitchen or building a new one, our expertise as a **Modular Kitchen Designer in Thane** ensures that you will have a kitchen that not only meets your needs but exceeds your expectations. Trust us to bring your vision to life with a kitchen that is both beautiful and functional. </p>`
  },
  {
    id: 4,
    slug: "interior-designing",
    img: [
      imageIndex.OTHER1,
      imageIndex.OTHER2,
      imageIndex.OTHER3
    ],
    h1: 'Interior Designing',
    alt:'Interior Designing ',
    title: "Other Expertise",
    descp1: `<p>When it comes to creating a living or working environment that truly reflects your personality and style, professional interior designing is the key. At HNY Studio, based in Waghbil-Thane West, Thane, we specialize in bringing your vision to life through our expert interior designing services. Whether you’re looking to revamp your home, renovate your office, or create a unique commercial space, our team of skilled designers is here to ensure your dreams become a reality.</p>`,
    descp2: `<strong>Why Choose HNY Studio for Interior Designing?</strong>`,
    descp3: `<p>At HNY Studio, we understand that each space is unique and requires a personalized approach. Our interior designing process begins with an in-depth consultation to understand your needs, preferences, and budget. From there, we create a customized design plan that aligns with your vision while incorporating the latest trends and technologies in the interior design industry.</p>`,
    descp4: `<p>Our commitment to excellence in interior designing means that we pay close attention to every detail, from selecting the perfect color palettes and materials to arranging furniture and accessories in a way that maximizes space and functionality. We pride ourselves on our ability to blend aesthetics with practicality, ensuring that your space is not only beautiful but also comfortable and functional.</p>`,
    descp5: `<strong>Our Comprehensive Interior Designing Services</strong>`,
    descp6: ` <p> HNY Studio offers a wide range of interior designing services tailored to meet the specific needs of our clients in Thane and beyond. Our services include:</p>`,
    descp7: `<strong> Residential Interior Designing:</strong> <br /> Transform your home into a sanctuary with our expert residential interior designing services. We handle everything from kitchen renovations and bathroom remodels to complete home makeovers.`,
    descp8: `<p> <strong> Commercial Interior Designing:</strong> <br />  Create an inviting and efficient workspace with our commercial interior designing solutions. We work on offices, retail spaces, restaurants, and more, ensuring that your business environment is both stylish and conducive to productivity.</p>`,
    descp9: `<strong> Architectural Interior Designing:</strong> <br /> Our architectural interior designing services focus on enhancing the structural aspects of your space, creating a cohesive and harmonious design that complements the architecture.`,
    descp10: `<strong>Why Interior Designing Matters</strong>`,
    descp11: `<p>Investing in professional interior designing is not just about making your space look good; it's about improving your overall quality of life. A well-designed space can boost your mood, increase productivity, and even improve the value of your property. At HNY Studio, we are passionate about helping our clients achieve these benefits through our dedicated interior designing services.</p>`,
    descp12: `<h5><strong> Get Started with HNY Studio Today </strong /> </h5>`,
    descp13: `<strong> Ready to transform your space? </strong> `,
    descp14: `<p>Contact HNY Studio in Waghbil-Thane West, Thane, to schedule a consultation and learn more about how our interior designing services can help you create the environment of your dreams. Whether you’re looking for a simple update or a complete overhaul, our team is here to guide you every step of the way.</p>`,
  },
  {
    id: 5,
    slug: "commercial-interior-designer-thane",
    img: [
      imageIndex.COM1,
      imageIndex.COM2,
      imageIndex.COM3
    ],
    h1: 'Commercial Interior Designer in Thane',
    alt:'Commercial Interior Designer in Thane',
    title: "Commercial Interior Design",
    descp: `<strong>Commercial Interior Designer in Thane:</strong> <br/> Transforming Workspaces with Innovative Designs <div style="margin-bottom: 20px;">`,
    descp2: `<p style="margin-bottom: 20px;">When it comes to creating a workspace that is both functional and aesthetically pleasing, choosing the right Commercial Interior Designer in Thane is crucial. A well-designed commercial space not only reflects the brand's identity but also enhances employee productivity and leaves a lasting impression on clients. At HNY Studio, we specialize in transforming commercial interiors into spaces that inspire creativity, collaboration, and success.</p>`,
    descp3: `<p style="margin-bottom: 20px;">Our team of experienced designers understands the unique challenges and requirements of commercial interiors. Whether it's an office, retail space, restaurant, or any other commercial establishment, our focus is on creating environments that are tailor-made to suit the needs of the business while ensuring that the space is optimized for efficiency and comfort.</p>`,
    descp4: `<strong style="display: block; margin-top: 20px; margin-bottom: 10px;">Tailored Design Solutions</strong>`,
    descp5: `<p style="margin-bottom: 20px;">At HNY Studio, we believe that every business is unique, and so should be its workspace. As a leading <strong>Commercial Interior Designer in Thane</strong>, we take the time to understand our client's vision, brand values, and specific needs. This allows us to create custom design solutions that not only meet but exceed expectations. Our approach combines creativity with practicality, ensuring that the final design is not just visually appealing but also functional and conducive to the business's operations.</p>`,
    descp6: `<strong style="display: block; margin-top: 20px; margin-bottom: 10px;">Expertise in Various Commercial Sectors</strong>`,
    descp7: `<p style="margin-bottom: 20px;">Our expertise spans across various commercial sectors, including corporate offices, retail outlets, hospitality spaces, and healthcare facilities. Each sector has its own set of design requirements, and our team is well-versed in addressing these challenges. Whether it's creating an open-plan office that fosters collaboration or designing a retail space that enhances customer experience, our team ensures that the design aligns with the business objectives.</p>`,
    descp8: `<strong style="display: block; margin-top: 20px; margin-bottom: 10px;">Sustainable and Innovative Design</strong> `,
    descp9: `<p style="margin-bottom: 20px;">Sustainability is at the core of our design philosophy. We understand the importance of creating spaces that are not only beautiful but also environmentally responsible. As a <strong>Commercial Interior Designer in Thane</strong>, we incorporate sustainable practices into our designs, such as using energy-efficient lighting, eco-friendly materials, and smart technology. Our designs are not only forward-thinking but also help businesses reduce their carbon footprint and operational costs.</p>`,
    descp10: `<strong style="display: block; margin-top: 20px; margin-bottom: 10px;">Why Choose HNY Studio?</strong>`,
    descp11: `<p style="margin-bottom: 20px;">Choosing HNY Studio as your <strong>Commercial Interior Designer in Thane</strong> means partnering with a team that is dedicated to delivering exceptional results. Our design process is collaborative, involving our clients at every stage to ensure that the final outcome aligns with their vision. We pride ourselves on our attention to detail, commitment to quality, and ability to deliver projects on time and within budget.</p>`,
    descp12: `<p style="margin-bottom: 20px;">In conclusion, a well-designed commercial space is a valuable asset to any business. It enhances the brand image, improves employee morale, and creates a positive experience for clients. At HNY Studio, we are committed to providing top-notch interior design services that transform commercial spaces into thriving business environments. Contact us today to discuss how we can help you create the perfect workspace for your business.</p>`,
  },
];
